<template>
  <div class="">
    <h4>{{ text.title }}</h4>
    <form class="space-y-4" @submit.prevent="submitForm()">
      <div class="border-b pb-6 space-y-2">
        <p class="font-bold">Profile picture</p>
        <div class="relative flex flex-col items-center space-y-4">
          <img class="w-40 h-40 border-2 border-black object-cover rounded-full object-center"
            v-if="displayImages.profile" :src="displayImages.profile" />
          <div class="h-40 w-40 border-2 border-black rounded-full bg-gray-200 grid place-items-center p-5" v-else>
            <p class="text-center text-sm">Upload profile picture</p>
          </div>
          <ButtonComponent :ordinary="true" :small="true" @click="openImageUploader('page-profile-image')">Upload
          </ButtonComponent>
          <input @change="uploadImage($event, 'profile')" type="file" class="hidden" name="page-profile-image"
            id="page-profile-image" accept="image/*" />
        </div>
      </div>
      <div class="border-b pb-6 space-y-2">
        <p class="font-bold">Cover picture</p>
        <div class="relative flex flex-col items-center space-y-4">
          <img class="w-full h-40 object-cover rounded-xl object-center" v-if="displayImages.cover"
            :src="displayImages.cover" />
          <div class="h-40 rounded-xl w-full bg-gray-200 grid place-items-center" v-else>
            <p class="text-center text-sm">Upload cover picture</p>
          </div>
          <ButtonComponent :ordinary="true" :small="true" @click="openImageUploader('page-cover-image')">Upload
          </ButtonComponent>
          <input @change="uploadImage($event, 'cover')" type="file" class="hidden" name="page-cover-image"
            id="page-cover-image" accept="image/*" />
        </div>
      </div>

      <div class="space-y-2" v-if="hasAdminAccess">
        <p class="font-bold">Bio</p>
        <InputComponent type="textarea" :required="true" title="Description" v-model="page.description" />
        <InputComponent :required="true" title="Title" v-model="page.title" />
        <SelectComponent :required="true" title="Level" v-model="page.level" :options="levels" />
        <InputComponent v-if="['dcc', 'lcc', 'lcb'].includes(page.level)" :required="true" title="Name of DCC"
          v-model="page.nameOfDcc" />
        <InputComponent v-if="['lcc', 'lcb'].includes(page.level)" :required="true" title="Name of LCC"
          v-model="page.nameOfLcc" />
        <InputComponent v-if="['lcb'].includes(page.level)" :required="true" title="Name of LCB"
          v-model="page.nameOfLcb" />
        <InputComponent :required="true" title="Phone Number" v-model="page.phone.number" />
        <InputComponent :required="true" type="email" title="Email" v-model="page.email" />
      </div>
      <div class="form md:col-span-2">
        <div v-if="data" class="flex items-center justify-between space-x-4">
          <ButtonComponent v-if="hasAdminAccess" :ordinary="true" @click="togglePageActivation()" type="dark"
            :fullWidth="true">
            {{ page.isActive ? 'Deactivate' : 'Activate' }} page
          </ButtonComponent>
          <ButtonComponent v-if="hasAdminAccess" :ordinary="true" type="error" @click="deletePage()">
            Delete
          </ButtonComponent>
        </div>
        <ButtonComponent :fullWidth="true">{{ text.button }}</ButtonComponent>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'PageModal',
}
</script>

<script setup>
import { useStore } from "vuex";
import { computed, inject, ref } from "@vue/runtime-core";
import { CreatePageDTO, UpdatePageDTO } from "@/models/dtos/page-dto";
import Page from "@/models/page";
import notificationService from "@/services/notification.service";
import { useRouter } from "vue-router";
import ButtonComponent from "../ButtonComponent.vue";
import SelectComponent from "../SelectComponent.vue";
import SelectOption from "@/models/select-option";
import phoneNumberFormatter from "@/providers/phoneNumberFormatter";

// eslint-disable-next-line
const props = defineProps({
  data: {
    type: Page,
    required: false,
  },
});

const store = useStore();
const router = useRouter();
const openModal = inject("openModal");
// eslint-disable-next-line
const emit = defineEmits(["close", "onClose", "closeWithFunction"]);

const propData = computed(() => {
  return props.data
    ? new UpdatePageDTO(props.data)
    : CreatePageDTO.prototype.createEmpty();
});

const levels = ['HQ', 'DCC', 'LCC', 'LCB'].map((level) => new SelectOption(level))

const page = ref(propData.value);
const displayImages = ref({ cover: page.value.coverPicture, profile: page.value.profilePicture });
const uploadedImages = ref({ cover: null, profile: null });


const hasAdminAccess = computed(() => {
  return store.state.adminAccessRoles.includes(store.state.auth.user?.role);
});

const hasCreatorAccess = computed(() => {
  return store.state.creatorAccessRoles.includes(store.state.auth.user?.role);
});

const submitForm = async () => {
  const formData = new FormData();
  delete page.value.coverPicture;
  delete page.value.profilePicture;

  const { cover, profile } = uploadedImages.value
  cover ? formData.append("coverPicture", cover) : null;
  profile ? formData.append("profilePicture", profile) : null;

  for (const data in page.value) {
    formData.append(data, page.value[data]);
  }

  formData.delete('phone')

  formData.append("phone[number]", phoneNumberFormatter(page.value.phone.number));

  if (!props.data) {
    const message = await store.dispatch("pages/create", formData);
    notificationService.success(message);
  } else {
    const message = await store.dispatch("pages/update", formData);
    notificationService.success(message);
  }
  await emit("closeWithFunction");
};

const togglePageActivation = async () => {
  const formData = new FormData();
  formData.append("id", page.value.id)
  formData.append("isActive", !page.value.isActive)

  const message = await store.dispatch("pages/update", formData);
  notificationService.success(message);
  await emit("closeWithFunction");
}

const text = computed(() => {
  return props.data
    ? { title: "Update page details", button: "Update" }
    : { title: "Create a new page", button: "Create Page" };
});

const openImageUploader = (id) => {
  const image = document.getElementById(id);
  image.click();
};

const uploadImage = ($event, type) => {
  const image = $event.target.files[0];
  if (type == 'cover') {
    displayImages.value.cover = URL.createObjectURL(image);
    uploadedImages.value.cover = image;
  } else {
    displayImages.value.profile = URL.createObjectURL(image);
    uploadedImages.value.profile = image;
  }
};

const goToPages = () => {
  router.push({ name: "pages" });
};

const deletePage = () => {
  emit("close");
  openModal(
    "delete",
    { type: "page", id: page.value.id },
    "medium",
    goToPages
  );
};

// eslint-disable-next-line
defineExpose({
  text,
  levels,
  page,
  displayImages,
  hasAdminAccess,
  hasCreatorAccess,
  togglePageActivation,
  openImageUploader,
  uploadImage,
  submitForm,
});
</script>
