import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import AOS from "aos";
import { Icon } from "@iconify/vue";
import vClickOutsideUmd from "click-outside-vue3";

import OptionPill from "@/components/OptionPill.vue";
import InputComponent from "@/components/InputComponent.vue";
import SelectComponent from "@/components/SelectComponent.vue";
import FilterComponent from "@/components/FilterComponent.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";

import ModalConfig from "./models/modal-config";
import formatAndCapitalize from "./providers/formatAndCapitalize";
import phoneNumberFormatter from "./providers/phoneNumberFormatter";

import "aos/dist/aos.css";

const app = createApp(App);
app.use(store).use(router).use(vClickOutsideUmd);

AOS.init();

app.provide("openModal", (type, data, size, closeFunction, centered) => {
  store.dispatch(
    "modal/open",
    new ModalConfig(type, data, size, closeFunction, centered)
  );
});
app.provide("loading", () => {
  return store.state.loading.loading;
});
app.provide("formatAndCapitalize", formatAndCapitalize);
app.provide("phoneNumberFormatter", phoneNumberFormatter);

app.component("IconComponent", Icon);
app.component("OptionPill", OptionPill);
app.component("InputComponent", InputComponent);
app.component("SelectComponent", SelectComponent);
app.component("FilterComponent", FilterComponent);
app.component("ButtonComponent", ButtonComponent);
app.component("LoadingComponent", LoadingComponent);
app.mount("#app");
