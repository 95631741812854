<template>
  <div class="relative w-full" v-click-outside="close">
    <div class="w-full flex flex-col items-start space-y-2">
      <label class="xs">
        {{ title }}
      </label>
      <div class="custom-input rounded-[5px] py-3 cursor-pointer space-x-2" @click="toggle()">
        <div class="w-full">
          <p :class="['text-base', !model && ' text-gray-500']">{{ model || placeholderText }}</p>
        </div>
        <input :name="inputName" :required="required" :value="model" :placeholder="placeholder || title" disabled
          class="outline-none hidden w-full bg-transparent cursor-pointer" />
        <div class="text-gray-300 flex items-center border-i-grey">
          <span class="
              cursor-pointer
              flex
              items-center
              justify-center
              w-6
              h-6
              pl-2
              text-gray-600
              outline-none
              focus:outline-none
            ">
            <IconComponent icon="akar-icons:chevron-up" v-if="opened" />
            <IconComponent icon="akar-icons:chevron-down" v-else />
          </span>
        </div>
      </div>
    </div>
    <div class="
        absolute
        shadow
        bg-white
        top-100
        z-50
        w-full
        right-1
        rounded
        max-h-select
        overflow-y-auto
      " v-if="opened">
      <div class="flex flex-col w-full rounded-t rounded-b">
        <div class="w-full" v-if="options.length < 1">
          <div class="
              flex
              w-full
              items-center
              p-2
              pl-2
              border-transparent border-l-2
              relative
            ">
            <div class="w-full items-center flex">
              <div class="mx-2 select-none">No options available</div>
            </div>
          </div>
        </div>
        <div class="cursor-pointer w-full border-gray-100 hover:bg-gray-200" @click="selectOption(option)"
          v-for="option in options" :key="option.id">
          <div class="
              flex
              w-full
              items-center
              p-2
              pl-2
              border-transparent border-l-2
              relative
            ">
            <div class="w-full items-center flex">
              <div class="mx-2 select-none">{{ option.value }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectComponent',
}
</script>

<script setup>
import { computed, ref } from "@vue/runtime-core";

// eslint-disable-next-line
const props = defineProps({
  modelValue: {
    required: false,
    default: "",
  },
  title: {
    type: String,
    required: true,
  },
  required: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
  },
});

const opened = ref(false);

const toggle = () => {
  opened.value = !opened.value;
};

const close = () => {
  opened.value = false;
};

// eslint-disable-next-line
const emit = defineEmits(["click", "update:modelValue"]);

const model = computed(() => {
  return props.options.find((option) => props.modelValue === option.id)?.value
});

const placeholderText = computed(() => {
  return `Select ${props.placeholder || props.title}`;
});

const selectOption = (option) => {
  emit("click", option.id);
  emit("update:modelValue", option.id);
  toggle();
};

const inputName = props.title.toLocaleLowerCase().replaceAll(" ", "-");

// eslint-disable-next-line
defineExpose({ opened, inputName, model, placeholderText, toggle, close, selectOption });
</script>

<style scoped>
.top-100 {
  top: 130%;
}

.bottom-100 {
  bottom: 100%;
}

.max-h-select {
  max-height: 200px;
}
</style>
