export class CreatePageDTO {
  constructor(page) {
    this.title = page.title;
    this.description = page.description;
    this.email = page.email;
    this.phone = page.phone;
    this.level = page.level;
    this.nameOfDcc = page.nameOfDcc;
    this.nameOfLcc = page.nameOfLcc;
    this.nameOfLcb = page.nameOfLcb;
  }

  createEmpty() {
    return new CreatePageDTO({
      title: "",
      description: "",
      email: "",
      level: "hq",
      nameOfDcc: "",
      nameOfLcc: "",
      nameOfLcb: "",
      phone: { number: "" },
    });
  }
}

export class UpdatePageDTO extends CreatePageDTO {
  constructor(page) {
    super(page);
    this.id = page.id;
    this.isActive = page.isActive;
    this.coverPicture = page.coverPicture;
    this.profilePicture = page.profilePicture;
  }
}
