import Page from "./page";

export default class Post {
  constructor(post) {
    this.id = post._id;
    this.title = post.title;
    this.content = post.content;
    this.contentExcerpt = post.content.slice(0, 150);
    this.page = new Page(post.page);
    this.isPublished = post.isPublished;
    this.likesCount = post.likesCount;
    this.createdAt = post.createdAt;
    this.updatedAt = post.updatedAt;
  }

  get likes() {
    return `${this.likesCount.toLocaleString()} like${
      this.likesCount !== 1 ? "s" : ""
    }`;
  }
}
