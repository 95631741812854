import store from "@/store";
import authService from "../services/auth.service";

const roleGuard = async (to, next) => {
  if (to.matched.some((record) => record.meta.allowedRoles)) {
    if (to.meta.allowedRoles.includes(authService.getUser().role)) {
      next();
    } else if (
      to.path.includes("bookstore") &&
      authService.getUser().info.hasBookstoreAccess
    ) {
      next();
    } else {
      if (store.state.creatorAccessRoles.includes(authService.getUser().role)) {
        next({ name: "pages" });
      } else if (
        store.state.publishAccessRoles.includes(authService.getUser().role)
      ) {
        next({ name: "channels" });
      } else {
        next({ name: "dashboard" });
      }
    }
  } else {
    next();
  }
};

export default roleGuard;
