export class CreatePostDTO {
  constructor(post) {
    this.page = post.page;
    this.title = post.title;
    this.content = post.content;
    this.isPublished = post.isPublished;
  }

  createEmpty(page) {
    return new CreatePostDTO({ page, title: "", content: "" });
  }
}

export class UpdatePostDTO extends CreatePostDTO {
  constructor(post) {
    super(post);
    delete this.page;
    this.id = post.id;
  }
}
