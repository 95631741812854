<template>
  <div class="h-screen w-screen md:overflow-hidden">
    <ModalContainer />
    <HeaderComponent />
    <router-view v-slot="{ Component }">
      <component
        class="responsive-wrapper space-y-6 md:h-[calc(100vh-88px)] overflow-y-auto"
        :is="Component"
      ></component>
    </router-view>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import ModalContainer from "@/components/ModalContainer.vue";

export default defineComponent({
  name: "DashboardLayout",
  components: { HeaderComponent, ModalContainer },
});
</script>