import { createStore } from 'vuex'
import { Role } from '@/models/user'
import subscribers from './modules/subscribers'
import categories from './modules/categories'
import addresses from './modules/addresses'
import analytics from './modules/analytics'
import magazines from './modules/magazines'
import channels from './modules/channels'
import releases from './modules/releases'
import lessons from './modules/lessons'
import loading from './modules/loading'
import orders from './modules/orders'
import posts from './modules/posts'
import pages from './modules/pages'
import modal from './modules/modal'
import books from './modules/books'
import staff from './modules/staff'
import auth from './modules/auth'
import { CategoryFrequency, CategoryType } from '@/models/category'

export default createStore({
  state: {
    roles: [Role.administrator, Role.manager, Role.contentCreator, Role.contentEditor],
    categoryTypes: [CategoryType.timeBased, CategoryType.selfPaced],
    categoryFrequencies: [CategoryFrequency.daily, CategoryFrequency.weekly, CategoryFrequency.monthly],
    adminAccessRoles: [Role.administrator],
    analyticsAccessRoles: [Role.administrator, Role.manager],
    publishAccessRoles: [Role.administrator, Role.contentEditor],
    creatorAccessRoles: [Role.administrator, Role.contentCreator],
  },
  getters: {
    organizationLogo: () => process.env.VUE_APP_LOGO_URL
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    subscribers,
    categories,
    addresses,
    analytics,
    magazines,
    channels,
    releases,
    lessons,
    loading,
    orders,
    posts,
    pages,
    books,
    modal,
    staff,
    auth
  }
})
