<template>
  <header class="border-b-2 border-gray-300 relative z-40 h-[88px]">
    <div class="responsive-wrapper">
      <div class="flex items-center justify-between">
        <div class="flex items-center space-x-4">
          <img @click="toggleNav()" :src="logo" alt="Logo" class="w-10 h-10" />
          <div class="hidden md:flex items-center space-x-4">
            <router-link v-for="item in navigation" :key="item.name" :to="item.link">{{ item.name }}</router-link>
          </div>
        </div>
        <ProfileThumb :user="user" />
      </div>
    </div>
    <div v-if="navOpened" class="md:hidden absolute top-full w-screen border-t-2 border-gray-300"
      v-click-outside="closeNav">
      <div class="responsive-wrapper bg-white flex flex-col space-y-6">
        <router-link @click="toggleNav()" v-for="item in navigation" :key="item.name" :to="item.link">{{ item.name }}
        </router-link>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderComponent",
};
</script>

<script setup>
import { computed, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import ProfileThumb from "./ProfileThumb.vue";
import { onMounted, watch } from "vue";

const store = useStore();

const allRoutes = [
  {
    name: "Dashboard",
    link: "/dashboard",
  },
  {
    name: "Staff",
    link: "/staff",
  },
  {
    name: "Pages",
    link: "/pages",
  },
  {
    name: "Channels",
    link: "/channels",
  },
  {
    name: "Subscribers",
    link: "/subscribers",
  },
  {
    name: "Magazines",
    link: "/magazines",
  },
  {
    name: "Bookstore",
    link: "/bookstore",
  },
];

const navigation = ref([]);

const logo = computed(() => {
  return store.getters.organizationLogo;
});

const user = computed(() => {
  return store.state.auth.user;
});

const hasBookstoreAccess = computed(() => {
  return user.value.info.hasBookstoreAccess;
});

const populateNavigation = () => {
  if (store.state.adminAccessRoles.includes(user.value.role)) {
    navigation.value = allRoutes;
  } else if (store.state.analyticsAccessRoles.includes(user.value.role)) {
    navigation.value = [allRoutes[0]];
  } else if (store.state.creatorAccessRoles.includes(user.value.role)) {
    navigation.value = [allRoutes[2], allRoutes[3], allRoutes[5]];
  } else if (store.state.publishAccessRoles.includes(user.value.role)) {
    navigation.value = [allRoutes[3]];
  }
  if (user.value.info.hasBookstoreAccess) {
    navigation.value.push(allRoutes[6]);
  }
};

const navOpened = ref(false);

const toggleNav = () => {
  navOpened.value = !navOpened.value;
};

onMounted(() => {
  populateNavigation();
});

watch(hasBookstoreAccess, () => {
  populateNavigation();
});

const closeNav = () => {
  navOpened.value = false;
};

// eslint-disable-next-line
defineExpose({ navigation, navOpened, user, logo, toggleNav, closeNav });
</script>

<style scoped>
a.router-link-active,
a.router-link-exact-active {
  @apply font-semibold;
}
</style>