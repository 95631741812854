export default class Page {
  constructor(page) {
    this.id = page._id;
    this.title = page.title;
    this.profilePicture = page.profilePicture;
    this.coverPicture = page.coverPicture;
    this.description = page.description;
    this.email = page.email;
    this.phone = page.phone;
    this.isActive = page.isActive;
    this.level = page.level;
    this.followersCount = page.followersCount;
    this.nameOfDcc = page.nameOfDcc;
    this.nameOfLcc = page.nameOfLcc;
    this.nameOfLcb = page.nameOfLcb;
    this.createdAt = page.createdAt;
    this.updatedAt = page.updatedAt;
  }

  get followers() {
    return `${this.followersCount.toLocaleString()} follower${
      this.followersCount !== 1 ? "s" : ""
    }`;
  }

  get phoneNumber() {
    return `${this.phone.countryCode}${this.phone.number}`
  }
}
