import { httpService } from "@/services/http.service";
import { Pagination } from "@/models/pagination";
import Page from "@/models/page";

const state = {
  list: Pagination.prototype.createEmpty(),
};

const actions = {
  create: async ({ dispatch }, data) => {
    const response = await httpService.post("page", data);
    dispatch("getAll");
    return response.data.message;
  },
  getAll: async ({ commit }, options) => {
    const response = await httpService.get("page", { params: options });
    commit("SET_PAGES", response.data.data);
  },
  getEvery: async () => {
    const response = await httpService.get("page", { params: { limit: 100 } });
    return response.data.data.result.map((page) => new Page(page));
  },
  get: async (context, id) => {
    const response = await httpService.get(`page/${id}`);
    return new Page(response.data.data);
  },
  update: async ({ dispatch }, data) => {
    const response = await httpService.patch(`page/${data.get("id")}`, data);
    dispatch("getAll");
    return response.data.message;
  },
  delete: async ({ dispatch }, id) => {
    const response = await httpService.delete(`page/${id}`);
    dispatch("getAll");
    return response.data.message;
  },
};

const mutations = {
  SET_PAGES: (state, pages) => {
    const pagesPagination = new Pagination(pages, "pages/getAll");
    pagesPagination.result = pagesPagination.result.map(
      (_page) => new Page(_page)
    );
    state.list = pagesPagination;
  },
  CLEAR: (state) => {
    state.list = Pagination.prototype.createEmpty();
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
