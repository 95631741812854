import { httpService } from "@/services/http.service";
import Post from "@/models/post";

const actions = {
  create: async (context, data) => {
    const response = await httpService.post("post", data);
    return response.data;
  },
  getAllForPage: async (context, options) => {
    const response = await httpService.get("post", { params: options });
    const posts = response.data.data.result.map((post) => new Post(post));
    const pagination = {
      ...response.data.data.page,
      totalCount: response.data.data.totalCount,
    };
    return { posts, pagination };
  },
  get: async (context, data) => {
    const response = await httpService.get(`post/${data}`);
    return new Post(response.data.data);
  },
  update: async (context, data) => {
    const response = await httpService.patch(`post/${data.id}`, data);
    return response.data.message;
  },
  delete: async (context, data) => {
    const response = await httpService.delete(`post/${data}`);
    return response.data.message;
  },
};

export default {
  namespaced: true,
  actions,
};
