import formatAndCapitalize from "@/providers/formatAndCapitalize";
import { User } from "./user";

export default class Staff extends User {
  constructor(staff) {
    super(staff);
  }

  get channels() {
    return this.info.channels;
  }

  get pages() {
    return this.info.pages;
  }

  get magazines() {
    return this.info.magazines;
  }

  get hasBookstoreAccess() {
    return this.info.hasBookstoreAccess;
  }

  get capitalizedRole() {
    return formatAndCapitalize(this.role);
  }
}
