import { Role } from "@/models/user";
import { h } from "vue";
import { RouterView } from "vue-router";
import authLayout from "@/layouts/authLayout.vue";
import defaultLayout from "@/layouts/defaultLayout.vue";
import dashboardLayout from "@/layouts/dashboardLayout.vue";

const loadPage = (page) => {
  return () => import(`@/pages/${page}/${page}-page.vue`);
};

const routes = [
  { path: "/", redirect: "/dashboard" },
  {
    path: "/login",
    name: "login",
    component: loadPage("login"),
    meta: { title: "Login", layout: authLayout },
  },
  {
    path: "/forgot-password",
    name: "forgot password",
    component: loadPage("forgot-password"),
    meta: { title: "Forgot Password", layout: authLayout },
  },
  {
    path: "/reset-password",
    name: "reset password",
    component: loadPage("reset-password"),
    meta: { title: "Reset Password", layout: authLayout },
  },
  {
    path: "/set-password",
    name: "activate account",
    component: loadPage("activate-account"),
    meta: { title: "Activate your account", layout: authLayout },
  },
  {
    path: "/verify",
    name: "verify email",
    component: loadPage("verify-email"),
    meta: { title: "Verify your email address", layout: authLayout },
  },
  {
    path: "/pay",
    name: "payment",
    component: loadPage("payment"),
    meta: { title: "Subscribe to a Plan", layout: defaultLayout },
  },
  {
    path: "/manage-subscription",
    name: "manage-subscription",
    component: loadPage("manage-subscription"),
    meta: { title: "Manage Subscriptions", layout: defaultLayout },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: loadPage("dashboard"),
    meta: {
      title: "Dashboard",
      requiresAuth: true,
      layout: dashboardLayout,
      allowedRoles: [Role.administrator, Role.manager],
    },
  },
  {
    path: "/staff",
    name: "staff",
    component: loadPage("staff"),
    meta: {
      title: "Staff",
      requiresAuth: true,
      layout: dashboardLayout,
      allowedRoles: [Role.administrator],
    },
  },
  {
    path: "/pages",
    component: { render: () => h(RouterView) },
    meta: {
      requiresAuth: true,
      layout: dashboardLayout,
      allowedRoles: [Role.administrator, Role.contentCreator],
    },
    children: [
      {
        path: "",
        name: "pages",
        component: loadPage("pages"),
        meta: { title: "Pages" },
      },
      {
        path: ":id",
        name: "page",
        component: loadPage("page"),
        meta: { title: "Page" },
      },
    ],
  },
  {
    path: "/channels",
    component: { render: () => h(RouterView) },
    meta: {
      requiresAuth: true,
      layout: dashboardLayout,
      allowedRoles: [
        Role.administrator,
        Role.contentEditor,
        Role.contentCreator,
      ],
    },
    children: [
      {
        path: "",
        name: "channels",
        component: loadPage("channels"),
        meta: { title: "Channels" },
      },
      {
        path: ":id",
        name: "channel",
        component: loadPage("channel"),
        meta: { title: "Channel" },
      },
    ],
  },
  {
    path: "/magazines",
    component: { render: () => h(RouterView) },
    meta: {
      requiresAuth: true,
      layout: dashboardLayout,
      allowedRoles: [Role.administrator, Role.contentCreator],
    },
    children: [
      {
        path: "",
        name: "magazines",
        component: loadPage("magazines"),
        meta: { title: "Magazines" },
      },
      {
        path: ":id",
        name: "magazine",
        component: loadPage("magazine"),
        meta: { title: "Magazine" },
      },
    ],
  },
  {
    path: "/subscribers",
    name: "subscribers",
    component: loadPage("subscribers"),
    meta: {
      title: "Subscribers",
      requiresAuth: true,
      layout: dashboardLayout,
      allowedRoles: [Role.administrator],
    },
  },
  {
    path: "/bookstore",
    component: { render: () => h(RouterView) },
    meta: {
      requiresAuth: true,
      layout: dashboardLayout,
      allowedRoles: [Role.administrator],
    },
    children: [
      {
        path: "",
        name: "bookstore",
        component: loadPage("bookstore"),
        meta: { title: "Bookstore" },
      },
      {
        path: "orders",
        name: "orders",
        component: loadPage("orders"),
        meta: { title: "Bookstore Orders" },
      },
      {
        path: "shipping-zones",
        name: "shipping-zones",
        component: loadPage("shipping-zones"),
        meta: { title: "Bookstore Shipping Zones" },
      },
    ],
  },
  {
    path: "/settings",
    name: "settings",
    component: loadPage("settings"),
    meta: { title: "Settings", requiresAuth: true, layout: dashboardLayout },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: loadPage("404"),
    meta: { title: "Page Not Found", layout: defaultLayout },
  },
];

export default routes;
