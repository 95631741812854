export default class Magazine {
  constructor(magazine) {
    this.id = magazine._id;
    this.name = magazine.name;
    this.image = magazine.image;
    this.description = magazine.description;
    this.releasesCount = magazine.releasesCount;
    this.createdAt = magazine.createdAt;
    this.updatedAt = magazine.updatedAt;
  }

  get releases() {
    const count = this.releasesCount || 0;
    return `${count.toLocaleString()} release${count !== 1 ? "s" : ""}`;
  }

  init() {
    return new Magazine({});
  }
}
