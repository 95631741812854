<template>
  <div class="modal-container" :class="[modal.size !== 'xlarge' && modal.centered ? 'justify-center' : '']"
    v-if="modal.opened">
    <div class="z-50 bg-white p-10 rounded-lg w-full relative" :class="{
      'max-w-lg': modal.size == 'small',
      'max-w-xl': modal.size == 'medium',
      'max-w-2xl': modal.size == 'large',
      'max-w-3xl mt-10': modal.size == 'xlarge',
    }">
      <LoadingComponent width="40px" class="absolute right-10" />
      <component class="space-y-4" :is="activeContent" :data="modal.data" @close="closeModal()"
        @closeWithFunction="closeModalWithFunction()" />
    </div>
    <div class="fixed z-40 h-full w-full bg-black bg-opacity-50" @click="closeModal()"></div>
  </div>
</template>

<script>
export default {
  name: "ModalContainer",
};
</script>

<script setup>
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";
import PageModal from "@/components/modals/PageModal.vue";
import BookModal from "@/components/modals/BookModal.vue";
import PostModal from "@/components/modals/PostModal.vue";
import OrderModal from "@/components/modals/OrderModal.vue";
import StaffModal from "@/components/modals/StaffModal.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import LessonModal from "@/components/modals/LessonModal.vue";
import AddressModal from "@/components/modals/AddressModal.vue";
import ReleaseModal from "@/components/modals/ReleaseModal.vue";
import ChannelModal from "@/components/modals/ChannelModal.vue";
import MagazineModal from "@/components/modals/MagazineModal.vue";
import CategoryModal from "@/components/modals/CategoryModal.vue";
import BookDetailModal from "@/components/modals/BookDetailModal.vue";

const store = useStore();

const modal = computed(() => {
  return store.state.modal;
});

const activeContent = computed(() => {
  const type = store.state.modal.type;
  switch (type) {
    case "staff":
      return StaffModal;
    case "category":
      return CategoryModal;
    case "magazine":
      return MagazineModal;
    case "book":
      return BookModal;
    case "order":
      return OrderModal;
    case "address":
      return AddressModal;
    case "bookDetail":
      return BookDetailModal;
    case "channel":
      return ChannelModal;
    case "page":
      return PageModal;
    case "post":
      return PostModal;
    case "lesson":
      return LessonModal;
    case "release":
      return ReleaseModal;
    case "delete":
      return DeleteModal;
  }
});

const closeModal = () => {
  store.dispatch("modal/close");
};

const closeModalWithFunction = async () => {
  if (modal.value.closeFunction) {
    await modal.value.closeFunction();
  }
  closeModal();
};

// eslint-disable-next-line
defineExpose({ modal, activeContent });
</script>

<style lang="scss" scoped>
.modal-container {
  @apply fixed h-screen w-screen z-50 flex flex-col items-center overflow-y-auto;
}
</style>